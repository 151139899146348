import * as Sentry from "@sentry/browser";
import type { HandleServerError } from "@sveltejs/kit";
import { PUBLIC_GITHUB_SHA, PUBLIC_SENTRY_DSN } from "$env/static/public";

/** @type {import('@sveltejs/kit').HandleServerError} */
export const handleError: HandleServerError = function ({ error, event }) {
  Sentry.init({
    dsn: PUBLIC_SENTRY_DSN,
    release: PUBLIC_GITHUB_SHA || "unknown",

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  console.log(`Sentry.captureException: ${PUBLIC_SENTRY_DSN}`);
  Sentry.captureException(error, {
    extra: {
      locals: event.locals,
      params: event.params,
      platform: event.platform,
      request: event.request,
      url: event.url,
    },
  });
}
